import { Card } from "antd";
import React from "react";

import html from "./html/expert.html";

const Expert = () => (
  <Card>
    <iframe
      title="test"
      style={{ width: "100%", height: "100vh" }}
      srcDoc={html}
    ></iframe>
  </Card>
);

export default Expert;
