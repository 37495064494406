import React, { memo, useCallback, useState } from "react";
import { NodeResizer, useReactFlow } from "reactflow";
import { Input } from "antd";

import styles from "./styles.module.css";
import "./style.css";

export default memo(({ id, data }) => {
  const { setNodes } = useReactFlow();
  const [value, setValue] = useState(data.mainText);

  const handleChangeMainDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              mainText: e.target.value,
            };
          }

          return node;
        })
      );
      setValue(e.target.value);
    },
    [id, setNodes]
  );

  return (
    <div className={styles.wrapperNode}>
      <NodeResizer
        lineClassName={styles.resizeLine}
        handleClassName={styles.resizeHandle}
        minWidth={100}
        minHeight={30}
      />
      <div className={styles.textNode}>
        <Input.TextArea
          value={value}
          className="nodrag"
          bordered={false}
          style={{
            textAlign: "center",
            padding: 0,
            height: "100% !important",
          }}
          onChange={handleChangeMainDiv}
          autoSize={{ minRows: 1 }}
        />
      </div>
    </div>
  );
});
