import React from "react";
import { List } from "antd";
import { nodeTypesKeys } from "../../config";

import styles from "./sideMenu.module.css";
import BlockText from "./assets/BlockText.jpg";
import BlockO from "./assets/BlockO.jpg";
import BlockOr from "./assets/BlockOr.jpg";
import BlockSob from "./assets/BlockSob.jpg";
import BlockAnd from "./assets/BlockAnd.jpg";
import BlockTextBorder from "./assets/BlockTextBorder.jpg";
import BlockTextDouble from "./assets/BlockTextDouble.jpg";
import BlockTextTriple from "./assets/BlockTextTriple.jpg";
import BlockTextDoubleVertical from "./assets/BlockTextDoubleVertical.jpg";
import BlockTextTripleVertical from "./assets/BlockTextTripleVertical.jpg";
import DownloadButton from "../DownloadButton/DownloadButton";

export const SideMenu = () => {
  const handleDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const data = [
    {
      title: "Текст",
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.textNode),
    },
    {
      title: <img src={BlockTextBorder} alt="" style={{ width: "60%" }} />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.textNodeWithBlocksBorder),
    },
    {
      title: <img src={BlockText} alt="" style={{ width: "60%" }} />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.textNodeWithBlocks),
    },
    {
      title: <img src={BlockTextDouble} alt="" style={{ width: "60%" }} />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.doubleTextNode),
    },
    {
      title: (
        <img src={BlockTextDoubleVertical} alt="" style={{ width: "60%" }} />
      ),
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.doubleTextNodeVertical),
    },
    {
      title: <img src={BlockTextTriple} alt="" style={{ width: "60%" }} />,
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.tripleTextNode),
    },
    {
      title: (
        <img src={BlockTextTripleVertical} alt="" style={{ width: "60%" }} />
      ),
      handleDragStart: (event) =>
        handleDragStart(event, nodeTypesKeys.tripleTextNodeVertical),
    },
    {
      title: <img src={BlockOr} alt="" style={{ width: "30%" }} />,
      handleDragStart: (event) => handleDragStart(event, nodeTypesKeys.orNode),
    },
    {
      title: <img src={BlockSob} alt="" style={{ width: "30%" }} />,
      handleDragStart: (event) => handleDragStart(event, nodeTypesKeys.sobNode),
    },
    {
      title: <img src={BlockO} alt="" style={{ width: "30%" }} />,
      handleDragStart: (event) => handleDragStart(event, nodeTypesKeys.oNode),
    },
    {
      title: <img src={BlockAnd} alt="" style={{ width: "30%" }} />,
      handleDragStart: (event) => handleDragStart(event, nodeTypesKeys.andNode),
    },
  ];

  return (
    <div className={styles.side}>
      <List
        dataSource={data}
        renderItem={(item) => (
          <List.Item className={styles.menu_item}>
            <List.Item.Meta
              title={item.title}
              className={styles.item}
              draggable
              onDragStart={item.handleDragStart}
            />
          </List.Item>
        )}
      />
      <DownloadButton />
    </div>
  );
};
