import { Button } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { Position, internalsSymbol, useStore, getSmoothStepPath, EdgeLabelRenderer, useReactFlow } from 'reactflow';
import { configArrow } from '../utils';

import styles from './styles.module.css';


const colorKeys = {
  1: {
    name: 'Черный',
    code: '#000'
  },
  2: {
    name: 'Красный',
    code: '#f00'
  },
  3: {
    name: 'Синий',
    code: '#00f'
  },
  4: {
    name: 'Зеленый',
    code: '#0f0'
  },
}

const items = [
  {
    label: 'Черный',
    value: '1',
  },
  {
    label: 'Красный',
    value: '2',
  },
  {
    label: 'Синий',
    value: '3',
  },
  {
    label: 'Зеленый',
    value: '4',
  },
];

// returns the position (top,right,bottom or right) passed node compared to
function getParams(nodeA, nodeB) {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  // when the horizontal difference between the nodes is bigger, we use Position.Left or Position.Right for the handle
  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    // here the vertical difference between the nodes is bigger, so we use Position.Top or Position.Bottom for the handle
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position);
  return [x, y, position];
}

function getHandleCoordsByPosition(node, handlePosition) {
  // all handles are from type source, that's why we use handleBounds.source here
  const handle = node[internalsSymbol].handleBounds.source.find(
    (h) => h.position === handlePosition
  );

  let offsetX = handle.width / 2;
  let offsetY = handle.height / 2;

  // this is a tiny detail to make the markerEnd of an edge visible.
  // The handle position that gets calculated has the origin top-left, so depending which side we are using, we add a little offset
  // when the handlePosition is Position.Right for example, we need to add an offset as big as the handle itself in order to get the correct position
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle.height;
      break;
    default:
  }

  const x = node.positionAbsolute.x + handle.x + offsetX;
  const y = node.positionAbsolute.y + handle.y + offsetY;

  return [x, y];
}

function getNodeCenter(node) {
  return {
    x: node.positionAbsolute.x + node.width / 2,
    y: node.positionAbsolute.y + node.height / 2,
  };
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(source, target) {
  const [sx, sy, sourcePos] = getParams(source, target);
  const [tx, ty, targetPos] = getParams(target, source);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
}

function PointEdge({ id, source, target, sourceX, sourceY, sourcePosition, data }) {
  const { setEdges } = useReactFlow();
  const [show, setShow] = useState(false);
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  const onEdgeClick = useCallback((evt) => {
    evt.stopPropagation();
    setEdges(edges => edges.map(edge => {
      if (edge.id === id) {
        return {
          ...edge,
          data: {
            ...edge.data,
            countArrow: edge.data.countArrow > 2 ? 0 : edge.data.countArrow + 1,
          }
        }
      }
      return edge;
    }))
  }, [id, setEdges]);

  const handleChange = useCallback((e) => {
    setEdges(edges => edges.map(edge => {
      if (edge.id === id) {
        return {
          ...edge,
          data: {
            ...edge.data,
            color: colorKeys[e.target.value].code,
          }
        }
      }
      return edge;
    }))
  }, [id, setEdges]);

  const handleEnter = useCallback(() => setShow(true), []);
  const handleLeave = useCallback(() => setShow(false), []);

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { tx, ty, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <defs>
        <marker
          className="react-flow__arrowhead"
          id={`arrowclosed-id${id}`}
          markerWidth="12.5"
          markerHeight="12.5"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            stroke="#b1b1b7"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            fill="#b1b1b7"
            points="-5,-4 0,0 -5,4 -5,-4"
            style={{
              fill: data.color,
              stroke: data.color,
            }}
          />
        </marker>
      </defs>
      <path
        style={{
          fill: 'none',
          stroke: data.color,
        }}
        className={styles.pointEdgePath}
        d={edgePath}
        markerStart={`url(#${configArrow[data.countArrow].markerStart}-id${id})`}
        markerEnd={`url(#${configArrow[data.countArrow].markerEnd}-id${id})`}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
          className={`nodrag nopan`}
        >
          {show &&
            <div className={styles.actions}>
              <Button
                size='small'
                shape="circle"
                onClick={onEdgeClick}
                icon={<RedoOutlined />}
              />
              <select className={styles.box} onChange={handleChange}>
                {items.map((item) => (
                  <option
                    key={item.value}
                    value={item.value}
                    className={styles.option}
                    selected={data.color === colorKeys[item.value].code}
                  >
                    {item.label}
                  </option>
                ))}
              </select>
            </div>
          }
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default PointEdge;