import React from "react";
import { toPng } from "html-to-image";
import { Button } from "antd";
import moment from "moment";
import html2canvas from "html2canvas";

function downloadImage(dataUrl, name = "") {
  const a = document.createElement("a");

  a.setAttribute("download", `${name}-${moment().format("DD-MM-YYYY")}.png`);
  a.setAttribute("href", dataUrl);
  a.click();
}

export const SavePNGButton = ({ containerRef, fullScreen, name = "" }) => {
  const onClick = async () => {
    if (fullScreen) {
      html2canvas(document.getElementsByTagName("main")[0], {
        windowWidth: 2440,
        width: 2440,
        windowHeight: document.getElementsByTagName("main")[0].clientHeight,
        scale: 1,
        logging: false,
      }).then(function (canvas) {
        const dataURL = canvas.toDataURL();
        downloadImage(dataURL, name);
      });
    } else {
      toPng(containerRef.current).then((dataUrl) =>
        downloadImage(dataUrl, name)
      );
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button className="download-btn" onClick={onClick} danger>
        Сохранить
      </Button>
    </div>
  );
};
