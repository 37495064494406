import React, { memo, useCallback, useState } from "react";
import {
  Handle,
  NodeResizer,
  Position,
  useReactFlow,
  useStore,
} from "reactflow";
import { Input } from "antd";
import clsx from "clsx";
import { generateHandlePort, generateHandleStyle } from "../utils";

import styles from "./styles.module.css";
import "./style.css";

const handleStyleLeft = generateHandleStyle(10, "left");
const handleStyleTop = generateHandleStyle(10, "top");

const topPorts = generateHandlePort(10, "top");
const bottomPorts = generateHandlePort(10, "bottom");
const leftPorts = generateHandlePort(10, "left");
const rightPorts = generateHandlePort(10, "right");

export default memo(({ id, data }) => {
  const { setNodes } = useReactFlow();
  const [value, setValue] = useState(data.mainText);
  const [secondTopText, setSecondTopText] = useState(data.secondTopText);
  const [secondBottomText, setSecondBottomText] = useState(
    data.secondBottomText
  );
  const start = useStore((state) => state.connectionStartHandle);

  const handleChangeMainDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              mainText: e.target.value,
            };
          }

          return node;
        })
      );
      setValue(e.target.value);
    },
    [id, setNodes]
  );

  const handleChangeSecondTopDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              secondTopText: e.target.value,
            };
          }

          return node;
        })
      );
      setSecondTopText(e.target.value);
    },
    [id, setNodes]
  );

  const handleChangeSecondBottomDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              secondBottomText: e.target.value,
            };
          }

          return node;
        })
      );
      setSecondBottomText(e.target.value);
    },
    [id, setNodes]
  );

  return (
    <div style={{ height: "100%" }}>
      <NodeResizer minWidth={100} minHeight={114} />
      <div
        className={styles.node}
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: "4px",
          position: "relative",
          height: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ padding: "2px", borderBottom: "1px solid black" }}>
            <Input.TextArea
              value={value}
              className="nodrag"
              bordered={false}
              style={{
                textAlign: "center",
                padding: 0,
              }}
              onChange={handleChangeMainDiv}
              autoSize={{ minRows: 1 }}
            />
          </div>
          <div style={{ padding: "10px", borderBottom: "1px solid black" }}>
            <Input.TextArea
              value={secondTopText}
              className="nodrag"
              bordered={false}
              style={{
                textAlign: "center",
                padding: 0,
              }}
              onChange={handleChangeSecondTopDiv}
              autoSize={{ minRows: 1 }}
            />
          </div>
          <div style={{ padding: "10px" }}>
            <Input.TextArea
              value={secondBottomText}
              className="nodrag"
              bordered={false}
              style={{
                textAlign: "center",
                padding: 0,
              }}
              onChange={handleChangeSecondBottomDiv}
              autoSize={{ minRows: 1 }}
            />
          </div>
        </div>
        {topPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleLeft[index]}
            type="source"
            position={Position.Top}
            id={handleId}
          />
        ))}
        {rightPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleTop[index]}
            type="source"
            position={Position.Right}
            id={handleId}
          />
        ))}
        {bottomPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleLeft[index]}
            type="source"
            position={Position.Bottom}
            id={handleId}
          />
        ))}
        {leftPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleTop[index]}
            type="source"
            position={Position.Left}
            id={handleId}
          />
        ))}
      </div>
    </div>
  );
});
