import React from "react";
import { Avatar, Button, Card } from "antd";
import { Link } from "react-router-dom";
import { Layout } from "../../components";
import image from "./image/st_img.png";
import image_1 from "./image/st_img_1.png";
import image_2 from "./image/st_img_2.png";
import image_3 from "./image/st_img_3.png";
import image_4 from "./image/st_img_4.png";
import image_5 from "./image/unknown.png";
import image_6 from "./image/st_img_6.png";
import image_st_7 from "./image/st_img_7.png";
import image_st_8 from "./image/st_img_8.png";
import image_st_9 from "./image/st_img_9.png";
import image_st_10 from "./image/st_img_10.png";

import image_7 from "./image/img.png";
import image_8 from "./image/img_1.png";
import image_9 from "./image/img_2.png";
import image_10 from "./image/img_3.png";
import image_11 from "./image/img_4.png";
import image_12 from "./image/img_5.png";
import image_13 from "./image/img_6.png";
import image_14 from "./image/img_7.png";
import router from "../../utils/router";
import statRoute from "./route";

const items = [
  {
    image: image,
    title: "Диаграмма Парето",
    link: router.stat + statRoute.pareto,
    key: 1,
  },
  {
    image: image_1,
    title: "Диаграмма разброса",
    link: router.stat + statRoute.dispersion,
    key: 2,
  },
  {
    image: image_2,
    title: "Контрольные карты Шухарта",
    link: router.stat + statRoute.shewhart,
    key: 3,
  },
  {
    image: image_3,
    title: "Контрольные карты числа несоответствий",
    link: router.stat + statRoute.inconsistencies,
    key: 4,
  },
  {
    image: image_4,
    title: "Контрольные карты числа и долей несоответствий",
    link: router.stat + statRoute.prop_of_inconsistencies,
    key: 5,
  },
  {
    image: image_5,
    title: "Диаграмма Исикавы",
    link: router.stat + statRoute.fishbone,
    key: 6,
  },
  {
    image: image_6,
    title: "Проектирование бизнес-процессов (DFD, IDEF0, IDEF3)",
    link: router.stat + statRoute.reactFlow,
    key: 7,
  },
  {
    image: image_7,
    title: "Расчет рисков",
    link: router.stat + statRoute.risk,
    key: 8,
  },
  {
    image: image_8,
    title: "Основные определения",
    link: router.stat + statRoute.basicDefinitions,
    key: 9,
  },
  {
    image: image_9,
    title: "Обобщенная оценка",
    link: router.stat + statRoute.generalizedAssessment,
    key: 10,
  },
  {
    image: image_10,
    title: "Метод анализа иерархий",
    link: router.stat + statRoute.hierarchyAnalysis,
    key: 11,
  },
  {
    image: image_11,
    title: "Регрессия",
    link: router.stat + statRoute.regression,
    key: 12,
  },
  {
    image: image_12,
    title: "Метод экспертной оценки",
    link: router.stat + statRoute.expert,
    key: 13,
  },
  {
    image: image_13,
    title: "Временные ряды",
    link: router.stat + statRoute.timeSeries,
    key: 14,
  },
  {
    image: image_14,
    title: "Управление мотивацией",
    link: router.stat + statRoute.motivationManagement,
    key: 15,
  },
  {
    image: image_st_7,
    title: "Методика оценки поставщиков",
    link: router.stat + statRoute.methodologySuppliers,
    key: 16,
  },
  {
    image: image_st_8,
    title: "Оценочный лист",
    link: router.stat + statRoute.evaluationPaper,
    key: 17,
  },
  {
    image: image_st_9,
    title: "SWOT",
    link: router.stat + statRoute.swot,
    key: 18,
  },
  {
    image: image_st_10,
    title: "FMEA",
    link: router.stat + statRoute.fmea,
    key: 19,
  },
];

const StatMethods = () => {
  return (
    <Layout title="Статиcтические методы">
      <Card>
        {items.map((item) => (
          <Card.Grid key={item.key}>
            <Avatar size={128} src={item.image} />
            <p>{item.title}</p>
            <Link to={item.link}>
              <Button type="primary">Перейти к расчётам</Button>
            </Link>
          </Card.Grid>
        ))}
      </Card>
    </Layout>
  );
};

export default StatMethods;
