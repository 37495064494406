import { Card } from "antd";
import React, { useRef } from "react";
import { TableFirst } from "./TableFirst";
import { TableSecond } from "./TableSecond";
import { TableThird } from "./TableThird";
import { SavePNGButton } from "../../../components/SavePNGButton";

export const MethodologySuppliers = () => {
  const ref = useRef(null);
  return (
    <>
      <div
        ref={ref}
        style={{ display: "flex", flexDirection: "column", gap: 15 }}
      >
        <Card>
          <TableFirst />
        </Card>
        <Card>
          <TableSecond />
        </Card>
        <Card>
          <TableThird />
        </Card>
      </div>
      <Card>
        <SavePNGButton containerRef={ref} name="оценка-поставщиков" />
      </Card>
    </>
  );
};
