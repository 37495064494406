import { Button } from 'antd'
import React from 'react'

import styles from './styles.module.css';

import file1 from './assets/methodic.pdf';
import file2 from './assets/instruction.pdf';


const Header = () => {
  return (
    <div className={styles.wrapper}>
      <Button type="link" href={file1} target='_blank'>Методичка</Button>
      <Button type="link" href={file2} target='_blank'>Инструкция</Button>
    </div>
  )
}

export default Header