import React, { memo, useCallback, useState } from "react";
import {
  Handle,
  NodeResizer,
  Position,
  useReactFlow,
  useStore,
} from "reactflow";
import clsx from "clsx";
import { Input } from "antd";
import {
  defaultStyles,
  generateHandlePort,
  generateHandleStyle,
} from "../utils";

import styles from "./styles.module.css";
import "./style.css";

const handleStyleLeft = generateHandleStyle(10, "left");
const handleStyleTop = generateHandleStyle(10, "top");

const topPorts = generateHandlePort(10, "top");
const bottomPorts = generateHandlePort(10, "bottom");
const leftPorts = generateHandlePort(10, "left");
const rightPorts = generateHandlePort(10, "right");

export default memo(({ id, data }) => {
  const { setNodes } = useReactFlow();
  const [value, setValue] = useState(data.mainText);
  const [topLeftText, setTopLeftText] = useState(data.topLeftText);
  const [topRightText, setTopRightText] = useState(data.topRightText);
  const [bottomLeftText, setBottomLeftText] = useState(data.bottomLeftText);
  const [bottomRightText, setBottomRightText] = useState(data.bottomRightText);
  const start = useStore((state) => state.connectionStartHandle);

  const handleChangeMainDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              mainText: e.target.value,
            };
          }

          return node;
        })
      );
      setValue(e.target.value);
    },
    [id, setNodes]
  );

  const handleChangeTopLeftDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              topLeftText: e.target.value,
            };
          }

          return node;
        })
      );
      setTopLeftText(e.target.value);
    },
    [id, setNodes]
  );

  const handleChangeTopRightDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              topRightText: e.target.value,
            };
          }

          return node;
        })
      );
      setTopRightText(e.target.value);
    },
    [id, setNodes]
  );

  const handleChangeBottomLeftDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              bottomLeftText: e.target.value,
            };
          }

          return node;
        })
      );
      setBottomLeftText(e.target.value);
    },
    [id, setNodes]
  );

  const handleChangeBottomRightDiv = useCallback(
    (e) => {
      setNodes((nds) =>
        nds.map((node) => {
          if (node.id === id) {
            node.data = {
              ...node.data,
              bottomRightText: e.target.value,
            };
          }

          return node;
        })
      );
      setBottomRightText(e.target.value);
    },
    [id, setNodes]
  );

  return (
    <div style={{ height: "100%" }}>
      <NodeResizer minWidth={100} minHeight={50} />
      <div
        className={styles.node}
        style={{
          backgroundColor: "white",
          border: "1px solid black",
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Input
          value={topLeftText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            top: 0,
            left: "5px",
            zIndex: 10,
          }}
          onChange={handleChangeTopLeftDiv}
        />
        <Input
          value={topRightText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            top: 0,
            right: "5px",
            textAlign: "end",
            zIndex: 10,
          }}
          onChange={handleChangeTopRightDiv}
          autoSize={{ minRows: 1, maxRows: 1 }}
        />
        <Input
          value={bottomRightText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            bottom: 0,
            right: "5px",
            textAlign: "end",
            zIndex: 10,
          }}
          onChange={handleChangeBottomRightDiv}
          autoSize={{ minRows: 1, maxRows: 1 }}
        />
        <Input
          value={bottomLeftText}
          className="nodrag"
          bordered={false}
          maxLength={5}
          style={{
            ...defaultStyles,
            bottom: 0,
            left: "5px",
            zIndex: 10,
          }}
          onChange={handleChangeBottomLeftDiv}
          autoSize={{ minRows: 1, maxRows: 1 }}
        />
        <div style={{ padding: "10px" }}>
          <Input.TextArea
            value={value}
            className="nodrag"
            bordered={false}
            style={{
              textAlign: "center",
            }}
            onChange={handleChangeMainDiv}
            autoSize={{ minRows: 1 }}
          />
        </div>
        {topPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleLeft[index]}
            type="source"
            position={Position.Top}
            id={handleId}
          />
        ))}
        {rightPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleTop[index]}
            type="source"
            position={Position.Right}
            id={handleId}
          />
        ))}
        {bottomPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleLeft[index]}
            type="source"
            position={Position.Bottom}
            id={handleId}
          />
        ))}
        {leftPorts.map((handleId, index) => (
          <Handle
            className={clsx(styles.handle, {
              [styles.show]: start,
            })}
            key={handleId}
            style={handleStyleTop[index]}
            type="source"
            position={Position.Left}
            id={handleId}
          />
        ))}
      </div>
    </div>
  );
});
