import { Card } from "antd";
import React from "react";
import html from "./html/analysis_of_hierarchies.html";

const HierarchyAnalysis = () => {
  return (
    <Card>
      <iframe
        title="test"
        style={{ width: "100%", height: "100vh" }}
        srcDoc={html}
      ></iframe>
    </Card>
  );
};

export default HierarchyAnalysis;
