import { MarkerType } from "reactflow";
import CustomDefaultEdge from "./Edges/CustomDefaultEdge";
import PointEdge from "./Edges/PointEdge";
import AndNode from "./Nodes/AndNode";
import DoubleTextNode from "./Nodes/DoubleTextNode";
import DoubleTextNodeVertical from "./Nodes/DoubleTextNodeVertical";
import ONode from "./Nodes/ONode";
import OrNode from "./Nodes/OrNode";
import PointNode from "./Nodes/PointNode";
import SobNode from "./Nodes/SobNode";
import TextNode from "./Nodes/TextNode";
import TextWithBlocks from "./Nodes/TextWithBlocks";
import TextWithBlocksBorder from "./Nodes/TextWithBlocksBorder";
import TripleTextNode from "./Nodes/TripleTextNode";
import TripleTextNodeVertical from "./Nodes/TripleTextNodeVertical";

export const nodeTypes = {
  textNode: TextNode,
  pointNode: PointNode,
  doubleTextNode: DoubleTextNode,
  textNodeWithBlocks: TextWithBlocks,
  textNodeWithBlocksBorder: TextWithBlocksBorder,
  doubleTextNodeVertical: DoubleTextNodeVertical,
  tripleTextNode: TripleTextNode,
  tripleTextNodeVertical: TripleTextNodeVertical,
  orNode: OrNode,
  sobNode: SobNode,
  andNode: AndNode,
  oNode: ONode,
};

export const nodeTypesKeys = {
  textNode: "textNode",
  pointNode: "pointNode",
  doubleTextNode: "doubleTextNode",
  textNodeWithBlocks: "textNodeWithBlocks",
  textNodeWithBlocksBorder: "textNodeWithBlocksBorder",
  doubleTextNodeVertical: "doubleTextNodeVertical",
  tripleTextNodeVertical: "tripleTextNodeVertical",
  tripleTextNode: "tripleTextNode",
  orNode: "orNode",
  sobNode: "sobNode",
  andNode: "andNode",
  oNode: "oNode",
};

export const edgeTypes = {
  pointEdge: PointEdge,
  defaultEdge: CustomDefaultEdge,
};

export const centerPorts = ["a", "b", "c", "d"];

export const defaultNode = {
  textNode: {
    data: {
      mainText: "",
    },
  },
  textNodeWithBlocks: {
    data: {
      mainText: "",
      topLeftText: "",
      topRightText: "",
      bottomLeftText: "",
      bottomRightText: "",
    },
  },
  textNodeWithBlocksBorder: {
    data: {
      mainText: "",
      topLeftText: "",
      topRightText: "",
      bottomLeftText: "",
      bottomRightText: "",
    },
  },
  doubleTextNode: {
    data: {
      mainText: "",
      secondText: "",
    },
  },
  doubleTextNodeVertical: {
    data: {
      mainText: "",
      secondText: "",
    },
  },
  tripleTextNode: {
    data: {
      mainText: "",
      secondLeftText: "",
      secondRightText: "",
    },
  },
  tripleTextNodeVertical: {
    data: {
      mainText: "",
      secondTopText: "",
      secondBottomText: "",
    },
  },
};

export const defaultEdge = {
  markerStart: { type: MarkerType.ArrowClosed },
  markerEnd: { type: MarkerType.ArrowClosed },
  type: "defaultEdge",
  data: {
    color: "#000",
    countArrow: 0,
  },
};
