import { Card } from "antd";
import React from "react";

import html from "./html/time_series.html";

const TimeSeries = () => {
  return (
    <Card>
      <iframe
        title="test"
        style={{ width: "100%", height: "100vh" }}
        srcDoc={html}
      ></iframe>
    </Card>
  );
};

export default TimeSeries;
