import { Card } from "antd";
import React from "react";

import html from "./html/motivation_management.html";

const MotivationManagement = () => {
  return (
    <Card>
      <iframe
        title="test"
        style={{ width: "100%", height: "100vh" }}
        srcDoc={html}
      ></iframe>
    </Card>
  );
};

export default MotivationManagement;
