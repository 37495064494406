import React, { memo } from "react";
import { Handle, Position, useStore } from "reactflow";
import clsx from "clsx";
import { generateHandlePort, generateHandleStyle } from "../utils";

import styles from "./styles.module.css";
import "./style.css";

const handleStyleLeft = generateHandleStyle(5, "left");
const handleStyleTop = generateHandleStyle(5, "top");

const topPorts = generateHandlePort(5, "top");
const bottomPorts = generateHandlePort(5, "bottom");
const leftPorts = generateHandlePort(5, "left");
const rightPorts = generateHandlePort(5, "right");

export default memo(() => {
  const start = useStore((state) => state.connectionStartHandle);

  return (
    <div
      className={styles.node}
      style={{
        backgroundColor: "white",
        border: "1px solid black",
        position: "relative",
      }}
    >
      <div style={{ display: "flex" }}>
        <div style={{ padding: "4px" }} />
        <div style={{ borderLeft: "1px solid black", padding: "1px 5px" }}>
          X
        </div>
      </div>
      {topPorts.map((handleId, index) => (
        <Handle
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
          key={handleId}
          style={handleStyleLeft[index]}
          type="source"
          position={Position.Top}
          id={handleId}
        />
      ))}
      {rightPorts.map((handleId, index) => (
        <Handle
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
          key={handleId}
          style={handleStyleTop[index]}
          type="source"
          position={Position.Right}
          id={handleId}
        />
      ))}
      {bottomPorts.map((handleId, index) => (
        <Handle
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
          key={handleId}
          style={handleStyleLeft[index]}
          type="source"
          position={Position.Bottom}
          id={handleId}
        />
      ))}
      {leftPorts.map((handleId, index) => (
        <Handle
          className={clsx(styles.handle, {
            [styles.show]: start,
          })}
          key={handleId}
          style={handleStyleTop[index]}
          type="source"
          position={Position.Left}
          id={handleId}
        />
      ))}
    </div>
  );
});
