import React, { useCallback, useRef, useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  ConnectionMode,
  Controls,
  updateEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import { v4 as uuidv4 } from "uuid";
import SideMenu from "./components/SideMenu";
import ContextMenu from "./components/ContextMenu";
import ContextMenuEdge from "./components/ContextMenuEdge";
import {
  defaultEdge,
  defaultNode,
  edgeTypes,
  nodeTypes,
  nodeTypesKeys,
} from "./config";

import "reactflow/dist/style.css";
import styles from "./styles.module.css";

const initialNodes = [];
const initialEdges = [];

const ReactFlowPage = () => {
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const updateEdgeRef = useRef(null);
  const ref = useRef(null);

  const { project } = useReactFlow();

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [menu, setMenu] = useState(null);
  const [menuEdge, setMenuEdge] = useState(null);

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const handleEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      updateEdgeRef.current = true;
      setEdges((els) => updateEdge(oldEdge, newConnection, els));
    },
    [setEdges]
  );

  const handleConnect = useCallback(
    (params) => {
      setEdges((els) =>
        addEdge(
          {
            ...defaultEdge,
            ...params,
            type:
              params.target.split("-")[0] === "pointNode" ||
              params.source.split("-")[0] === "pointNode"
                ? "pointEdge"
                : "defaultEdge",
          },
          els
        )
      );
    },
    [setEdges]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");

      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top - 50,
      });
      const newNode = {
        id: `${type}-${uuidv4()}`,
        type,
        position,
        ...defaultNode[type],
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance, setNodes]
  );

  const handleNodeContextMenu = useCallback((event, node) => {
    event.preventDefault();

    if (node.type === "pointNode") {
      return;
    }

    const reactFlowBounds = ref.current.getBoundingClientRect();

    setMenu({
      id: node.id,
      top: event.clientY - reactFlowBounds.top,
      left: event.clientX - reactFlowBounds.left,
    });
  }, []);

  const handleEdgeContextMenu = useCallback((event, edge) => {
    event.preventDefault();

    const reactFlowBounds = ref.current.getBoundingClientRect();

    setMenuEdge({
      id: edge.id,
      top: event.clientY - reactFlowBounds.top,
      left: event.clientX - reactFlowBounds.left,
    });
  }, []);

  const handleEdgeUpdateStart = () => {
    updateEdgeRef.current = true;
  };

  const handleEdgeUpdateEnd = () => {
    updateEdgeRef.current = null;
  };

  const onConnectStart = useCallback((_, props) => {
    connectingNodeId.current = props;
  }, []);

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane && !updateEdgeRef.current) {
        const { top, left } = ref.current.getBoundingClientRect();
        const id = `pointNode-${uuidv4()}`;
        const newNode = {
          id,
          type: nodeTypesKeys.pointNode,
          position: project({
            x: event.clientX - left - 16,
            y: event.clientY - top - 16,
          }),
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({
            ...defaultEdge,
            id,
            type: "pointEdge",
            ...connectingNodeId.current,
            sourceHandle: connectingNodeId.current.handleId,
            source: connectingNodeId.current.nodeId,
            target: id,
          })
        );
      }
    },
    [project, setEdges, setNodes]
  );

  const handlePaneClick = useCallback(() => {
    setMenu(null);
    setMenuEdge(null);
  }, []);

  return (
    <div className={styles.wrapper}>
      <SideMenu />
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
        ref={reactFlowWrapper}
      >
        <ReactFlow
          ref={ref}
          nodes={nodes}
          connectionRadius={0}
          edges={edges}
          onInit={setReactFlowInstance}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={handleConnect}
          onEdgeUpdate={handleEdgeUpdate}
          onEdgeUpdateStart={handleEdgeUpdateStart}
          onEdgeUpdateEnd={handleEdgeUpdateEnd}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          onPaneClick={handlePaneClick}
          onNodeClick={handlePaneClick}
          onNodeContextMenu={handleNodeContextMenu}
          onEdgeContextMenu={handleEdgeContextMenu}
          connectionMode={ConnectionMode.Loose}
          fitView
          className="react-flow-node-resizer-example"
        >
          <Background />
          <Controls />
          {menu && <ContextMenu onClick={handlePaneClick} {...menu} />}
          {menuEdge && (
            <ContextMenuEdge onClick={handlePaneClick} {...menuEdge} />
          )}
        </ReactFlow>
      </div>
    </div>
  );
};

export default ReactFlowPage;
