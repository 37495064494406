const statRoute = {
  dispersion: "/dispersion",
  inconsistencies: "/inconsistencies",
  pareto: "/pareto",
  prop_of_inconsistencies: "/prop_of_inconsistencies",
  shewhart: "/shewhart",
  fishbone: "/fishbone",
  reactFlow: "/reactFlow",
  risk: "/risk",
  basicDefinitions: "/basicDefinitions",
  generalizedAssessment: "/generalizedAssessment",
  hierarchyAnalysis: "/hierarchyAnalysis",
  regression: "/regression",
  expert: "/expert",
  timeSeries: "/timeSeries",
  motivationManagement: "/motivationManagement",
  methodologySuppliers: "/methodologySuppliers",
  evaluationPaper: "/evaluationPaper",
  swot: "/swot",
  fmea: "/fmea",
};

export default statRoute;
